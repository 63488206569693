.wrapper {
  align-content: space-between;
  display: grid;
  grid-gap: 60px;
  grid-template-rows: auto auto;
  @media screen and (max-width: 992px) {
    grid-gap: 40px;
  }

  .image {
    border-radius: 16px;
    margin-bottom: 16px;
    width: 100%;
  }

  .status {
    color: #bab9bb;
    font-size: 18px;
    letter-spacing: -0.03em;
    line-height: 24px;
    margin-bottom: 8px;
    @media screen and (max-width: 1200px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    color: #323037;
    font-size: 24px;
    letter-spacing: -0.03em;
    line-height: 32px;
    @media screen and (max-width: 1200px) {
      font-size: 24px;
      line-height: 29px;
    }
  }

  .more {
    border-top: 1px solid #d9d9d9;

    color: #bab9bb;
    font-size: 18px;
    letter-spacing: -0.03em;
    line-height: 24px; 
    padding-top: 15px;

    transition: ease 0.5s;
  }

  &:hover {
    .more {

      border-top: 1px solid #39d292;
      color: #39d292;

      transition: ease 0.5s;
    }
  }
}